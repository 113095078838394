<template>
  <div>
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3">
        <b-col lg="1">
          <b-form-group label="Código">
            <b-form-input v-model="filter.code" v-on:keyup.enter="handleFilter"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Cliente">
            <b-form-input v-model="filter.advertiser" v-on:keyup.enter="handleFilter"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Tipo de produto">
            <b-form-select v-model="filter.product">
              <b-form-select-option :value="null">Todos</b-form-select-option>
              <b-form-select-option :value="8">Destaque de Venda</b-form-select-option>
              <b-form-select-option :value="9">Destaque de Locação</b-form-select-option>
              <b-form-select-option :value="7">Destaque de Lançamento</b-form-select-option>
              <b-form-select-option :value="10">Destaque de Temporada</b-form-select-option>
              <b-form-select-option :value="4">Super Destaque de Venda</b-form-select-option>
              <b-form-select-option :value="5">Super Destaque de Locação</b-form-select-option>
              <b-form-select-option :value="3">Super Destaque de Lançamento</b-form-select-option>
              <b-form-select-option :value="6">Super Destaque de Temporada</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Situação">
            <b-form-select v-model="filter.status">
              <b-form-select-option :value="'active'">Vigentes</b-form-select-option>
              <b-form-select-option :value="'inactive'">Expirados</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Cidade do anunciante">
            <vue-bootstrap-typeahead
                :data="cities"
                v-model="city"
                :serializer="s => s.city"
                placeholder="Digite a cidade do anunciante"
                @hit="filter.city = $event.city"
                :minMatchingChars="1"
                ref="advertiserCity"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col lg="12" class="py-3">
        <b-button @click="handleFilter" variant="primary" class="mr-2" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <i class="fas fa-search" v-if="!loading"></i> Pesquisar
        </b-button>
        <b-button variant="danger" class="mr-2" @click="handleClearFilter">
          <i class="fas fa-broom"></i> Limpar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="card mb-5">
      <div class="title-card">
        <div class="d-flex aligin-itens-center justify-content-between">
          <h5 class="float-left"><i class="fas fa-boxes"></i> Contratos</h5>
          <b-button class="ml-auto" variant="primary" v-b-modal.modalCreate>Novo Contrato</b-button>
        </div>
      </div>
      <div class="w-100 p-3">
        <b-table
            responsive
            class="mt-3"
            :current-page="currentPage"
            :per-page="perPage"
            :items="items"
            :busy="loading"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum contrato encontrado</h4>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template v-slot:cell(bonus)="data">
            {{ data.item.bonus ? 'Sim' : 'Não' }}
          </template>

          <template v-slot:cell(contract_date)="data">
            {{ data.item.contract_date | moment("DD/MM/YYYY") }}
          </template>

          <template v-slot:cell(final_contract_date)="data">
            {{ data.item.final_contract_date | moment("DD/MM/YYYY") }}
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex align-items-center justify-content-center">
              <b-button
                  class="mr-2"
                  v-b-tooltip.hover
                  title="Editar"
                  variant="primary"
                  size="sm"
                  @click.prevent="edit(data.item)"
              >
                Editar
              </b-button>

              <b-button
                  class="mr-2"
                  v-b-tooltip.hover
                  title="Renovar contrato"
                  variant="success"
                  size="sm"
                  @click.prevent="renew(data.item)"
              >
                Renovar
              </b-button>

              <b-button
                  v-b-tooltip.hover
                  title="Cancelar contrato"
                  variant="danger"
                  size="sm"
                  @click.prevent="cancel(data.item)"
              >
                Cancelar
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-center py-3">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0">
        </b-pagination>
      </div>
    </b-row>

    <b-modal id="modalRenew" title="Renovar Contrato" :cancel-title="'Não'" :ok-title="'Sim'" @ok="handleRenew">
      <p>
        Deseja renovar o contrato do cliente <strong>{{ selected.advertiser.name}}</strong>
        para o produto <strong>{{selected.product.name}}</strong> por mais 6 meses a partir de hoje?
      </p>
    </b-modal>

    <b-modal id="modalCancel" title="Cancelar Contrato" :cancel-title="'Não'" :ok-title="'Sim'" @ok="handleCancel">
      <p>
        Confirma o cancelamento do contrato do cliente <strong>{{ selected.advertiser.name}}</strong>
        para o produto <strong>{{selected.product.name}}</strong>?
      </p>
    </b-modal>

    <b-modal id="modalCreate" title="Novo Contrato" :cancel-title="'Cancelar'" :ok-title="'Salvar'" @ok="handleCreate">
      <b-form-group label="Cliente">
        <vue-bootstrap-typeahead
            :data="clients"
            v-model="clientSearch"
            :serializer="s => s.name"
            placeholder="Digite o nome ou código do cliente"
            @hit="payload.advertiser = $event.value"
        />
      </b-form-group>

      <b-form-group label="Tipo de produto">

        <multiselect
            v-model="payload.products"
            :options="products"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione os produtos"
            label="label"
            track-by="value"
        />
      </b-form-group>

      <b-form-group label="Quantidade de meses contratados">
        <b-form-input v-model="payload.quantity" :type="'number'" />
      </b-form-group>

      <b-form-group label="Quantidade de imóveis">
        <b-form-input v-model="payload.quantity_properties" :type="'number'" />
      </b-form-group>

      <b-form-group label="Início do contrato">
        <b-form-datepicker
            v-model="payload.start_date"
            :locale="'pt-br'"
            class="mb-2"
            :min="minDate"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group label="Bonificado">
        <b-form-select v-model="payload.bonus">
          <b-form-select-option value="1">Sim</b-form-select-option>
          <b-form-select-option value="0">Não</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-modal>

    <b-modal id="modalEdit" title="Editar Contrato" :cancel-title="'Cancelar'" :ok-title="'Salvar'" @ok="handleEdit">
      <b-form-group label="Cliente">
        <b-form-input :disabled="true" v-model="selected.advertiser.name" />
      </b-form-group>

      <b-form-group label="Tipo de produto">
        <b-form-input :disabled="true" v-model="selected.product.name" />
      </b-form-group>

      <b-form-group label="Quantidade de imóveis">
        <b-form-input v-model="selected.quantity" :type="'number'" />
      </b-form-group>

      <b-form-group label="Início do contrato">
        <b-form-input :value="selected.contract_date | moment('DD/MM/YYYY')" :disabled="true"/>
      </b-form-group>

      <b-form-group label="Bonificado">
        <b-form-select v-model="selected.bonus">
          <b-form-select-option value="1">Sim</b-form-select-option>
          <b-form-select-option value="0">Não</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>

import ContractService from '@/services/contract/ContractService';
import _ from 'underscore';
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  data() {
    const today = new Date();

    return {
      fields: ContractService.getFieldsTableContracts(),
      items: [],
      filters: false,
      filter: {
        status: 'active'
      },
      city: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      loading: false,
      clientSearch: '',
      clientSelected: {},
      minDate: today,
      products: [
        { value: 8, label: 'Destaque de Venda' },
        { value: 9, label: 'Destaque de Locação' },
        { value: 7, label: 'Destaque de Lançamento' },
        { value: 10, label: 'Destaque de Temporada' },
        { value: 4, label: 'Super Destaque de Venda' },
        { value: 5, label: 'Super Destaque de Locação' },
        { value: 3, label: 'Super Destaque de Lançamento' },
        { value: 6, label: 'Super Destaque de Temporada' }
      ],
      selected: {
        advertiser: {},
        product: {},
        bonus: false
      },
      clients: [],
      cities:[],
      payload: {
        products: [],
        quantity: 6,
        quantity_properties: 5,
        bonus: 0
      }
    };
  },
  async mounted() {
    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      await this.$router.push({name: 'login'});
    }
    await ContractService.getCities().then((response) => {
      this.cities = response.data;
    });
    await this.getAll(this.filter);
  },
  methods: {
    async getAll(filter) {
      this.loading = true;
      await ContractService.getList(filter).then((response) => {
        const {data} = response;
        this.items = data;
        this.totalRows = this.items.length;
      });
      this.loading = false;
    },

    async loadClients(query) {
      await ContractService.getClients(query).then((response) => {
        const {data} = response;
        this.clients = data.map((item) => {
            return {
              value: item.id,
              name: item.subsee_id + ' - ' + item.name
            }
        })
      });
      this.loading = false;
    },
    setCityFilter(evt) {
      console.log(evt);
    },
    handleFilter() {
      this.getAll(this.filter);
    },
    handleClearFilter() {
      this.filter = {};
      this.getAll();
    },
    renew(contract) {
      this.selected = contract;
      this.$bvModal.show('modalRenew');
    },
    edit(contract) {
      this.selected = contract;
      this.$bvModal.show('modalEdit');
    },
    cancel(contract) {
      this.selected = contract;
      this.$bvModal.show('modalCancel');
    },
    async handleEdit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      await ContractService.edit({
        id: this.selected.id,
        quantity: this.selected.quantity,
        bonus: this.selected.bonus
      }).then(() => {
        this.getAll(this.filter);
        this.$bvModal.hide('modalEdit');
      });
      this.loading = false;
    },
    async handleRenew(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      await ContractService.renewContract({
        contract_id: this.selected.id
      }).then(() => {
        this.getAll(this.filter);
        this.$bvModal.hide('modalRenew');
      });
      this.loading = false;
    },
    async handleCancel(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.loading = true;
      await ContractService.cancelContract({
        contract_id: this.selected.id
      }).then(() => {
        this.getAll(this.filter);
        this.$bvModal.hide('modalCancel');
      });
      this.loading = false;
    },
    async handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      await ContractService.save(this.payload).then(() => {
        this.getAll(this.filter);
        this.$bvModal.hide('modalCreate');
        this.payload = {
          quantity_properties: 5,
          quantity: 6
        };
      });
      this.loading = false;
    },
  },
  watch: {
    clientSearch: _.debounce(function(addr) { this.loadClients(addr) }, 500),
    city() {
      if(this.city === "" || this.city === null) {
        delete this.filter.city;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.hide {
  display: none;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
}
</style>
