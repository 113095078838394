/* eslint-disable class-methods-use-this */
import Service from '../Service';
import http from "@/http";

class ContractService extends Service {
  constructor() {
    super('/contract');
  }

  cancelContract(payload) {
    return http.post(`${this.baseUrl}/cancel`, payload);
  }

  renewContract(payload) {
    return http.post(`${this.baseUrl}/renew`, payload);
  }

  getClients(params) {
    return http.get(`${this.baseUrl}/clients`, { params });
  }

  getCities(params) {
    return http.get(`${this.baseUrl}/cities`, { params });
  }

  getFieldsTableContracts() {
    return [
      {
        key: 'advertiser.subsee_id',
        label: 'Cód. Cliente',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'advertiser.name',
        label: 'Cliente',
        sortable: true,
      },
      {
        key: 'product.name',
        label: 'Tipo de anúncio',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'quantity',
        label: 'Quantidade',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'bonus',
        label: 'Bonificado',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'contract_date',
        label: 'Contradado em',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'final_contract_date',
        label: 'Data de término',
        sortable: true,
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ];
  }
}

const contractService = new ContractService();
export default contractService;
